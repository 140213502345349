import './App.css';
import Header from './components/Header';
import Intro from './components/Intro';
import Experience from './components/Experience';
import Education from './components/Education';
import Projects from './components/Projects';
import SustainabilitySection from './components/SustainabilitySection';
import Footer from './components/Footer';
import styled from 'styled-components';

const BodyWrapper = styled.div`
  margin: 0 auto;
  width: 50%;

  @media(max-width: 600px) {
    width: 90%;
  }
`;

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <div className="App-body">
          <Intro />
          <BodyWrapper>
            <Experience />
            <Projects />
            <Education />
            <SustainabilitySection />
          </BodyWrapper>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
