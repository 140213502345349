import React from 'react';
import styled from 'styled-components';
import Headshot from '../../images/Headshot.JPG';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const IntroWrapper = styled.div`
    position: relative;
    color: white;
    img {
        width: 100%;
        object-fit: cover;
        min-height: 100vh;
    }
`;

const Description = styled.div`
    padding: 3rem 3rem 3rem 2rem;
    margin: 0 1rem;

    background-image: linear-gradient(to bottom, rgba(138,170,229,0.2), rgba(0,0,0,0.5));
    max-width: 45%;

    position: absolute;
    top: 10%;

    .headerP {
        font-family: "Sacramento";
        font-size: 1.7em;
    }

    a {
        padding: 10px 20px;
        text-transform: uppercase;
        border: none;
        color: white;
        background-color: var(--blue);
        font-weight: bold;
        text-decoration: none;
        font-size: 1.2em;
    }
    @media(max-width: 800px) {
        max-width: 100%;
        margin: 0 auto;
    }
`;
const Intro = () => {

    return (
        <IntroWrapper id="about">
            <img src={Headshot} alt="Jeanette Pranin Corin" />
            <Description>
                <h1>
                    Hello world!
                </h1>
                <p>
                    I'm Jeanette Pranin Corin, a NYC-based Full-Stack Software Engineer. It's a pleasure to e-meet you! With expertise in web development, microservices, and server-side development, I've had the privilege of working across diverse companies, from mega-corporations to start-ups.
                </p>
                <p>
                    Beyond coding, I'm passionate about sustainability and living a low-waste lifestyle. When I'm not at the office, you'll find me exploring farmer's markets for package-free groceries or dropping off compost at the local collection center.
                </p>
                <p>
                  Want to learn more about my journey?
                </p>
                <AnchorLink href="#experience" offset='115'>
                    Yes, Please!
                </AnchorLink>
            </Description>
        </IntroWrapper>
    );

};
export default Intro;