import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const NavUl = styled.ul`
    list-style-type: none;
    font-family: "LatoRegular";
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    

    li a {
        display: block;
        text-decoration: none;
        text-align: center;
        padding: 14px 16px;
        color: white;
        transition: color ease-in 0.2s;
    }
      
`;
const DesktopWrapper = styled.div`
  background: var(--blue);
`;
const DesktopNavigation = () => {
    return (
        <DesktopWrapper>
            <h1>Jeanette Pranin Corin</h1>
            <nav>
                <NavUl>
                    <li><AnchorLink href="#about" offset="115">About</AnchorLink></li>
                    <li><AnchorLink href="#experience" offset="115">Experience</AnchorLink></li>
                    <li><AnchorLink href="#projects" offset="115">Projects</AnchorLink></li>
                    <li><AnchorLink href="#education" offset="115">Education</AnchorLink></li>
                    <li><AnchorLink href="#sustainability" offset="115">Sustainability</AnchorLink></li>
                </NavUl>
            </nav>
        </DesktopWrapper>
    );
};
export default DesktopNavigation;