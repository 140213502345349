import React from 'react';
import styled from 'styled-components';

const ExperienceWrapper = styled.div`
`;

const EXPERIENCE = [
    {
        "name": "Senior Software Engineer",
        "company": "fabric",
        "dates": "Jul 2022 - Present",
        "description": "<p>Spearheaded the creation of an operational alerts service in Node.js, enabling the visibility of operational errors and scaling to handle thousands of alerts per minute with DynamoDB, increasing system reliability.</p><p>Led the revamp of a multichannel service using Redis cache, resulting in an ultra-low latency system, improving data organization for merchants.</p><p>Directed the migration of a custom CMS solution from Node.js serverless to a containerized Java Micronaut/DynamoDB implementation, ensuring zero downtime over the course of the migration (six months).</p><p>Ensured 80% unit test coverage and integration test coverage for all major projects.</p><p>Designed and implemented product-wide features, established on-call processes and standards, and promoted the use of reusable components across the organization.</p><p>Organized epics, planned and wrote user stories, reviewed pull requests, and approved technical decisions for frontend and backend projects.</p><p>Conducted knowledge-sharing sessions on accessibility, feature flagging, Datadog monitoring, shadow releases, and web components.</p>",
    },
    {
        "name": "Software Engineer II",
        "company": "fabric",
        "dates": "Mar 2021 - Jul 2022",
        "description": "<p>Tech lead for the Developer Experience (DX) team.</p> Led the development of the Self-Service Account Provisioning feature, which enables potential customers to try out our products before purchasing the platform. Reduces time to onboard from 6 weeks to 1 day.<p>Led team's safe & fast migration from a fragmented UX to a new design system over the course of 1 month. Scope included new navigation, reusable React components & hooks, & rollout via feature flag.</p><p>Managed migration from disjointed, manually updated knowledge base on Redocly to developer portal hosted on ReadMe. Consolidated & transformed dozens of markdown files & OpenAPI yaml files into a single repository with automated CICD. Wrote new content geared towards onboarding developers onto our platform, including Recipes & Quickstart Guides.</p><p>Tech lead for the Content Management System product called XM (Experience Manager).</p><p> Developed & approved designs in data management for read-intensive, multi-tenant DynamoDB.</p><p> Implemented features for the CMS's front-end including: localization support to enable users to create pages in multiple languages across sales channels, and a navigation content type that allowed for scheduling, channel segmentation, & navigation hierarchy.</p>"
    },
    {
        "name": "Lead Full Stack Developer",
        "company": "Package Free",
        "dates": "Feb 2020 - Dec 2020",
        "description": "<p>Led technical initiatives for both the retail and blog websites of the Package Free brand. Migrated 'Trash is for Tossers' from WordPress into a NextJS application, expanding customizability of brand message & integrating opportunities for cross-selling products.</p><p>Increased conversion 100% YoY by integrating a loyalty program and automating promotions & campaigns.</p><p>Introduced Agile software development life cycle to the company, enabling the tracking of productivity metrics by C-suite.</p>"
    },
    {
        "name": "Web Application Developer",
        "company": "IBM Cognitive Apps",
        "dates": "Aug 2019 - Feb 2020",
        "description": "Developed new features for Ruby, an internal marketing platform that enables IBM marketers to create effective campaigns, using ReactJS & NodeJS."
    },
    {
        "name": "Agile Squad Leader",
        "company": "IBM Cloud Garage",
        "dates": "Jul 2018 - Aug 2019",
        "description": "<p>Build five new features for Hertz's brand-new iOS application, as a developer consultant.</p><p>Led a team of 15 iOS, Android, and Microservices developers through agile practices.</p><p>Increase squad's velocity by 30% by serving as contact point between developers and Product Owners, providing resources such as relevant software, and providing detailed breakdowns on requirements.</p>"
    },
    {
        "name": "Cognitive Software Engineer",
        "company": "IBM Watson",
        "dates": "Aug 2017 - Jul 2018",
        "description": "Architected a microservice that uploads snapshots of PHI to Cloud Object Storage. Led migration from Softlayer to COS, increasing upload speed by 40% & reducing failure rate by 20%."
    },
];

const Experience = () => {

    return (
        <div id="experience">
            <h1>
                Experience
            </h1>
            <ExperienceWrapper>
                {EXPERIENCE.map(experience => (
                    <div>
                        <p><b>{experience.name}</b> • {experience.company} • {experience.dates}</p>
                        <div dangerouslySetInnerHTML={{__html: experience.description}}></div>
                    </div>
                ))}
            </ExperienceWrapper>
        </div>
    );

};
export default Experience;