import React from 'react';
import styled from 'styled-components';
import GithubIcon from '../../images/github.svg';
import LinkedinIcon from '../../images/linkedin.svg';
import InstagramIcon from '../../images/instagram.svg';

const FooterWrapper = styled.div`
    background-color: var(--blue);
    padding: 2rem 0;
    color: white;
    text-align: center;

    img {
        width: 30px;
    }
`;

const FooterIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        padding: 0 1.2rem;
    }
`;


const FOOTER_LINKS = [
    {
        "link": "https://github.com/tenaciousj",
        "altText": "Jeanette Pranin Corin Github Profile",
        "src": GithubIcon
    },
    {
        "link": "https://www.linkedin.com/in/jeanettepranin/",
        "altText": "Jeanette Pranin Corin Linkedin Profile",
        "src": LinkedinIcon
    },
    {
        "link": "https://www.instagram.com/dontgiveupcycle/",
        "altText": "Jeanette Pranin Corin DontGiveUpcycle Instagram Profile",
        "src": InstagramIcon
    }
];
const Footer = () => {
    return (
        <FooterWrapper>
            <FooterIconWrapper>
                {FOOTER_LINKS.map(footerLink => (
                    <a href={footerLink.link} target="_blank" rel="noopener noreferrer" title={footerLink.altText}>
                        <img src={footerLink.src} alt={footerLink.altText} />
                    </a>
                ))}
            </FooterIconWrapper>
            <p>© Jeanette Pranin Corin 2024</p>
        </FooterWrapper>
    );
}

export default Footer;