import React from 'react';
import styled from 'styled-components';

const SustainabilityWrapper = styled.div`
    li {
        padding-top: 10px;
       
    }
    a {
        color: var(--blue);
    }
    
`;

const SustainabilitySection = () => {
    return (
        <SustainabilityWrapper id="sustainability">
            <h1>Sustainability</h1>
            <p>In my free time I run <a href="https://www.instagram.com/dontgiveupcycle/" target="_blank" rel="noopener noreferrer">@dontgiveupcycle</a>, an Instagram account which provides tips and tricks on eco living with upcycling, reusing, reducing, and recycling. I am also building out <a href="https://dontgiveupcycle.com" target="_blank" rel="noopener noreferrer">dontgiveupcycle.com</a>, which will contain blog posts about topics that pertain to sustainability, eco-friendly business practices, and more!</p>
            <p>I personally love to discover sustainable swaps for items that I use daily, as well as find new uses for things I might have otherwise thrown away. If you are curious, please give <a href="https://www.instagram.com/dontgiveupcycle/" target="_blank" rel="noopener noreferrer">@dontgiveupcycle</a> a follow and see what you think! I'm happy to answer any questions about low-waste living over there.</p>
            <p>Check out some of my favorite small businesses who aim to help consumers reduce waste in their everyday lives!</p>
            <ul>
                <li><a href="https://packagefreeshop.com" target="_blank" rel="noopener noreferrer">Package Free</a>: Zero Waste Products that reduce waste in your daily routine</li>
                <li><a href="https://precyclenyc.com" target="_blank" rel="noopener noreferrer">Precycle</a>: Zero Waste Grocery store that delivers groceries to you with recyclable or no packaging</li>
                <li><a href="https://fountain.nyc" target="_blank" rel="noopener noreferrer">Fountain House + Body</a>: A social enterprise that provides meaningful paid work opportunities to people with serious mental illness AND produces environmentally sustainable, low-waste products</li>
                <li><a href="https://bitetoothpastebits.com" target="_blank" rel="noopener noreferrer">Bite Toothpaste Bits</a>: Water-free toothpaste tablets in recyclable glass jars</li>
                <li><a href="https://meowmeowtweet.com/" target="_blank" rel="noopener noreferrer">Meow Meow Tweet</a>: Positively Natural Vegan Skin Care</li>
                <li><a href="https://www.brushwithbamboo.com/" target="_blank" rel="noopener noreferrer">Brush with Bamboo</a>: Plant-based, compostable toothbrushes</li>
            </ul>
        </SustainabilityWrapper>
    );
}

export default SustainabilitySection;