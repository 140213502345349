import React from 'react';
import styled from 'styled-components';

const ProjectsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 1em;
    @media(max-width: 600px) {
        grid-template-columns: 100%;
    }
`;

const SingleProject = styled.div`
    a {
        color: var(--blue);
    }
`;

const PROJECTS_LIST = [
    {
      "name": "fabric Developer Portal",
      "link": "https://developer.fabric.inc/",
      "description": "A developer portal to onboard fabric's customers onto the fabric e-commerce platform. Includes in-depth guides, API documentation, and a tool to test API calls."
    },
    {
        "name": "Package Free",
        "link": "https://packagefreeshop.com",
        "description": "Built with Shopify via Liquid and jQuery, Package Free is a robust DTC e-commerce brand specializing in low-waste and sustainable products that can be used in everyday life. Features include a Gift Registry, Accounts, Loyalty Rewards Points, Customized Discount promotions via Shopify Ruby Scripts, and more."
    },
    {
        "name": "Trash is for Tossers Website",
        "link": "https://trashisfortossers.com",
        "description": "A React app built via the Frontity Wordpress platform, this blog site connects to a Wordpress as a headless CMS to render beautiful, customized designs catered to the sustainability lifestyle brand 'Trash is for Tossers'. Initially built in 1 month and re-iterated upon multiple times, this site includes features like Shoppable Product Carousels, mobile-first design, affiliate ad marketing, and more."
    },
    {
        "name": "Ruby",
        "link": "N/A",
        "description": "A React app connecting to DB2 and built for internal IBM use, Ruby is a marketing planning and execution platform that consolidates the campaign creation process into a single user interface. Migrated from class components to React Hooks to modularize logic and make code more readable."
    },
    {
        "name": "Hertz iOS App",
        "link": "N/A",
        "description": "An iOS app built in Swift, the Hertz iOS app allows customers to rent cars, use loyalty points, and set account preferences using their mobile devices. Utilized storyboards, Swift, bits of Objective-C, and external calls to private microservices backend."
    }
];
const Projects = () => {

    return (

        <div id="projects">
            <h1>Projects</h1>
            <ProjectsGrid>
                {PROJECTS_LIST.map(project => (
                    <SingleProject>
                        <p>
                            <b>{project.name}</b>
                        </p>
                        <p>{project.description}</p>
                        {project.link.startsWith('http') &&
                            <a href={project.link} target="_blank" rel="noopener noreferrer">View Site</a>
                        }
                    </SingleProject>
                ))}
            </ProjectsGrid>
        </div>

    );

};
export default Projects;