import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import HamburgerMenuIcon from '../../images/hamburger_menu.svg';

const MobileNavWrapper = styled.div`
    position: relative;
    h1 {
        font-size: 2em;
        text-align: left;
        padding: 15px 10px;
    }

    .hamburgerMenu {
        width: 50px;
        position: absolute;
        top: 13px;
        right: 0;
        cursor: pointer;
    }

    ul {
        list-style-type:  none;
        li {
            text-align: left;
            a {
                text-decoration: none;
                color: white;
            }
            padding-bottom: 0.7em;
        }
        padding-left: 0.5rem;
        margin: 0;
    }

    @keyframes slideFromTop {
        from {
          transform: translateX(-100%);
        }
      
        to {
            transform: translateX(0);
            
        }
      }
    nav {
       animation-name: slideFromTop;
       animation-duration: 0.5s;
       animation-timing-function: ease-out;
    }
    background-color: var(--blue);
    
`;

const MobileNavigation = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
    const toggleNavigationBar = () => {
        if (isMobileMenuOpen) {
            setIsMobileMenuOpen(false);
        } else {
            setIsMobileMenuOpen(true);
        }
    };

    return (
        <MobileNavWrapper>
            <img className="hamburgerMenu" src={HamburgerMenuIcon} alt="Mobile Navigation Bar Menu" onClick={toggleNavigationBar} />
            <h1>Jeanette Pranin Corin</h1>

            {isMobileMenuOpen &&
                <nav>
                    <ul>
                        <li onClick={toggleNavigationBar}><AnchorLink href="#about" offset="255">About</AnchorLink></li>
                        <li onClick={toggleNavigationBar}><AnchorLink href="#experience" offset="255">Experience</AnchorLink></li>
                        <li onClick={toggleNavigationBar}><AnchorLink href="#projects" offset="255">Projects</AnchorLink></li>
                        <li onClick={toggleNavigationBar}><AnchorLink href="#education" offset="255">Education</AnchorLink></li>
                        <li onClick={toggleNavigationBar}><AnchorLink href="#sustainability" offset="255">Sustainability</AnchorLink></li>
                    </ul>
                </nav>
            }
        </MobileNavWrapper>
    );
};
export default MobileNavigation;