import React, { useState } from 'react';
import DesktopNavigation from '../Navigation/Desktop';
import MobileNavigation from '../Navigation/Mobile';
import Breakpoint from '../ResponsiveUtilities/Breakpoint';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    return (
        <header className="App-header">
            <Breakpoint name="desktop">
                <DesktopNavigation />
            </Breakpoint>
            <Breakpoint name="tablet">
                <DesktopNavigation />
            </Breakpoint>
            <Breakpoint name="phone">
                <MobileNavigation isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen}  />
            </Breakpoint>
        </header>
    );
};

export default Header;