import React from 'react';
import styled from 'styled-components';

const EducationWrapper = styled.div`
    .goCats {
        font-size: 0.7em;
        a {
            text-decoration: none;
            color: black;
        }
        a:hover {
            text-decoration: underline;
        }
    }
`;

const Education = () => {
    return (
        <EducationWrapper id="education">
            <h1>
                Education
            </h1>
            <p><b>Masters of Science in Computer Science</b> • Northwestern University</p>
            <p>Thesis: "Counting on the Abacus: Exploring the Effects of Tangible Interaction on Learning"</p>
            <p><b>Bachelor of Arts in Computer Science</b> • Northwestern University</p>
            <p>Concentrations in Interfaces and Artificial Intelligence</p>
            <p className="goCats"><i><a href="https://en.wikipedia.org/wiki/Northwestern_Wildcats" target="_blank" rel="noopener noreferrer">Go 'Cats!</a></i></p>
        </EducationWrapper>
    );
}

export default Education;